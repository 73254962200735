* {
  font-family: "Roboto", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.toggle-overflow {
  overflow: hidden;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-gradient-custom {
  background: #202020;
  background: -webkit-linear-gradient(
    289deg,
    #202020 0%,
    #202020 50%,
    #4a2424 100%
  );
  background: linear-gradient(290deg, #202020 0%, #202020 50%, #4a2424 100%);
}

.bg-window {
  background-color: #191919;
}

.window-bg-img {
  background-image: url("./static/images/blob.svg");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 40%;
}
.nav-bg {
  background-color: #323232;
}
.navsearch-bg {
  background-color: #4c4c4c;
}
.navbar-input:focus-within + .navbar-input-placeholder {
  opacity: 0;
  transition: opacity 0.15s;
}
.purplish {
  background-color: rgba(244, 51, 51, 0.744);
}
.card-bg {
  background-color: rgba(0, 0, 0, 0.6);
}
.scrollbar::-webkit-scrollbar,
.notifications-container::-webkit-scrollbar,
.artists-dropdown::-webkit-scrollbar,
.select-dropdown::-webkit-scrollbar,
.artists-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track,
.notifications-container::-webkit-scrollbar-track,
.artists-dropdown::-webkit-scrollbar-track,
.select-dropdown::-webkit-scrollbar-track,
.artists-container::-webkit-scrollbar-track {
  background: #888;
  border-radius: 4px;
}
.select-dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb,
.notifications-container::-webkit-scrollbar-thumb,
.artists-dropdown::-webkit-scrollbar-thumb,
.select-dropdown::-webkit-scrollbar-thumb,
.artists-container::-webkit-scrollbar-thumb {
  background: #f1f1f1;
  border-radius: 4px;
}
.select-dropdown::-webkit-scrollbar-thumb {
  background-color: #888 !important;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover,
.notifications-container::-webkit-scrollbar-thumb:hover,
.artists-dropdown::-webkit-scrollbar-thumb:hover,
.select-dropdown::-webkit-scrollbar-thumb:hover,
.artists-container::-webkit-scrollbar-thumb:hover {
  background: rgb(219, 219, 219);
}
.recharts-legend-item {
  font-size: 1rem;
}
.right-bg {
  background-color: #323232;
  position: relative;
}
/* Loader1 */
.right-bg .loader,
.notifications-section .loader,
.artists-container .loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 32px 0 #fff, -32px 0 #fff;
  position: absolute;
  animation: flash 0.5s ease-out infinite alternate;
  right: 0;
  left: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes flash {
  0% {
    background-color: #fff2;
    box-shadow: 32px 0 #fff2, -32px 0 #fff;
  }
  50% {
    background-color: #fff;
    box-shadow: 32px 0 #fff2, -32px 0 #fff2;
  }
  100% {
    background-color: #fff2;
    box-shadow: 32px 0 #fff, -32px 0 #fff2;
  }
}

/* Loader 2 */

.right-bg .loader2,
.artists-container .loader3,
.notifications-container .loader3 {
  display: none;
  width: 20px;
  height: 20px;
  --c: linear-gradient(#c43334 0 0);
  --r1: radial-gradient(farthest-side at bottom, #c43334 93%, #0000);
  --r2: radial-gradient(farthest-side at top, #c43334 93%, #0000);
  background: var(--c), var(--r1), var(--r2), var(--c), var(--r1), var(--r2),
    var(--c), var(--r1), var(--r2);
  background-repeat: no-repeat;
  animation: db2 1s infinite alternate;
  margin: 10px auto 0 auto;
}
@keyframes db2 {
  0%,
  25% {
    background-size: 4px 0, 4px 2px, 4px 2px, 4px 0, 4px 2px, 4px 2px, 4px 0,
      4px 2px, 4px 2px;
    background-position: 0 50%, 0 calc(50% - 1px), 0 calc(50% + 1px), 50% 50%,
      50% calc(50% - 1px), 50% calc(50% + 1px), 100% 50%, 100% calc(50% - 1px),
      100% calc(50% + 1px);
  }
  50% {
    background-size: 4px 100%, 4px 2px, 4px 2px, 4px 0, 4px 2px, 4px 2px, 4px 0,
      4px 2px, 4px 2px;
    background-position: 0 50%, 0 calc(0% - 1px), 0 calc(100% + 1px), 50% 50%,
      50% calc(50% - 1px), 50% calc(50% + 1px), 100% 50%, 100% calc(50% - 1px),
      100% calc(50% + 1px);
  }
  75% {
    background-size: 4px 100%, 4px 2px, 4px 2px, 4px 100%, 4px 2px, 4px 2px,
      4px 0, 4px 2px, 4px 2px;
    background-position: 0 50%, 0 calc(0% - 1px), 0 calc(100% + 1px), 50% 50%,
      50% calc(0% - 1px), 50% calc(100% + 1px), 100% 50%, 100% calc(50% - 1px),
      100% calc(50% + 1px);
  }
  95%,
  100% {
    background-size: 4px 100%, 4px 2px, 4px 2px, 4px 100%, 4px 2px, 4px 2px,
      4px 100%, 4px 2px, 4px 2px;
    background-position: 0 50%, 0 calc(0% - 1px), 0 calc(100% + 1px), 50% 50%,
      50% calc(0% - 1px), 50% calc(100% + 1px), 100% 50%, 100% calc(0% - 1px),
      100% calc(100% + 1px);
  }
}

.error-icon {
  background-color: rgba(255, 0, 0, 0.702);
}
.warning-icon {
  background-color: rgba(255, 166, 0, 0.707);
}
.leak-card-bg {
  background-color: #4c4c4c;
  animation: fadeIn 0.5s ease-in-out forwards;
  cursor: pointer;
}
.artist-card {
  animation: fadeIn 0.5s ease-in-out forwards;
}
.notification {
  animation: fadeIn 0.5s ease-in-out forwards;
}
.no-data-msg {
  animation: fadeIn 0.5s ease-in-out forwards;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.nav-icons-hover:hover {
  background-color: #4c4c4c;
}
.profile-hover:hover {
  background-color: rgba(255, 0, 0, 0.702);
}
.dropdown-bg {
  background-color: #282828 !important;
}
.section-dropdown-heading:hover,
.section-dropdown-heading2:hover {
  background-color: #aa2324 !important;
}
.dropdown-section,
.dropdown-section2 {
  transition: background-color 0.3s ease-in-out;
}
.dropdown-section {
  position: relative;
}
.dropdown-arrow,
.scnd-dropdown-arrow {
  transition: transform 0.3s;
}
.menu-icon:hover {
  background-color: #4c4c4c !important;
}
.loading-page .loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: absolute;
  transform: rotate(45deg) translateY(-50%);
  right: 0;
  left: 0;
  margin: 0 auto;
  top: 50%;
}
.loading-page .loader::before {
  content: "";
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0;
  top: -24px;
  animation: animloader 4s ease infinite;
}
.loading-page .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  animation: animloader2 2s ease infinite;
}

@keyframes animloader {
  0% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0),
      24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
  }
  12% {
    box-shadow: 0 24px white, 24px 24px rgba(255, 255, 255, 0),
      24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
  }
  25% {
    box-shadow: 0 24px white, 24px 24px white, 24px 48px rgba(255, 255, 255, 0),
      0px 48px rgba(255, 255, 255, 0);
  }
  37% {
    box-shadow: 0 24px white, 24px 24px white, 24px 48px white,
      0px 48px rgba(255, 255, 255, 0);
  }
  50% {
    box-shadow: 0 24px white, 24px 24px white, 24px 48px white, 0px 48px white;
  }
  62% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px white, 24px 48px white,
      0px 48px white;
  }
  75% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0),
      24px 48px white, 0px 48px white;
  }
  87% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0),
      24px 48px rgba(255, 255, 255, 0), 0px 48px white;
  }
  100% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0),
      24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
  }
}

@keyframes animloader2 {
  0% {
    transform: translate(0, 0) rotateX(0) rotateY(0);
  }
  25% {
    transform: translate(100%, 0) rotateX(0) rotateY(180deg);
  }
  50% {
    transform: translate(100%, 100%) rotateX(-180deg) rotateY(180deg);
  }
  75% {
    transform: translate(0, 100%) rotateX(-180deg) rotateY(360deg);
  }
  100% {
    transform: translate(0, 0) rotateX(0) rotateY(360deg);
  }
}
.redish-btn {
  background-color: #f43334;
}
.artists-container {
  position: relative;
  width: 99%;
  margin: 0 auto;
}
.notifications-wrapper {
  background-color: #4c4c4c;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.fa-bell {
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  left: 0;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
}
.not-number {
  top: -7px;
  right: 3px;
  font-size: 13px;
  font-weight: 400;
  color: #f43334;
}
.notifications-navbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.705);
  padding: 0 15px 15px 15px;
  display: flex;
  align-items: center;
}
.notifications-navbar .fa-arrow-left {
  width: 100px;
  text-align: center;
  font-size: 20px;
  transition: transform 0.2s, background-color 0.2s;
  padding: 7px 0;
  border-radius: 6px;
}
.notifications-navbar .fa-arrow-left:hover {
  background-color: rgba(0, 0, 0, 0.181);
}

.notifications-navbar .nav-link {
  margin-left: 10px;
  background-color: rgba(0, 0, 0, 0.401);
  color: #fff;
  font-size: 14px;
  padding: 7px 20px;
  border-radius: 6px;
  cursor: pointer;
}
.notifications-num {
  color: #f43334;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  margin-left: 10px;
  font-size: 12px;
}
.notification-first {
  background: #ae2729;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notification-first div {
  color: rgba(67, 110, 103, 1);
  font-weight: bold;
  background-color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notification-second {
  background: #b62c2e;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
.light-gray {
  color: rgba(242, 242, 242, 0.8);
}
.profile-container {
  background: linear-gradient(
    107.89deg,
    #202020 0.46%,
    #321a1a 47.56%,
    #202020 100%
  );
  border: 14px solid #191919;
  border-radius: 15px;
}
.profile-picture {
  border: 2px solid rgba(255, 255, 255, 0.527);
}
.profile-input {
  background: rgba(255, 255, 255, 0.05);
}
.profile-section:nth-child(3) {
  border-right: 0.5px solid rgba(255, 255, 255, 0.3);
  animation: slideIn 0.5s forwards;
}

.landing-left-part {
  animation: slideIn 0.5s forwards;
}
.landing-right-part {
  animation: slideIn2 0.5s forwards;
}

.profile-section:nth-child(4) {
  animation: slideIn2 0.5s backwards;
}

@keyframes slideIn {
  from {
    transform: translateX(-30px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideIn2 {
  from {
    transform: translateX(30px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.update-btn {
  background-color: rgba(244, 51, 52, 0.75);
  width: fit-content;
  transition: background-color 0.2s;
}
.update-btn:hover {
  background-color: #db2d2e;
}
.update-btn:hover i {
  transform: rotate(-90deg);
}
.update-btn i {
  transition: all 0.3s;
}
.profile-section input {
  transition: all 0.3s;
}
.profile-section input:hover {
  background-color: #2d2424;
}
.artists-dropdown .loader4 {
  width: 15px;
  height: 15px;
  --c: linear-gradient(#fff 0 0);
  --r1: radial-gradient(farthest-side at bottom, #fff 93%, #0000);
  --r2: radial-gradient(farthest-side at top, #fff 93%, #0000);
  background: var(--c), var(--r1), var(--r2), var(--c), var(--r1), var(--r2),
    var(--c), var(--r1), var(--r2);
  background-repeat: no-repeat;
  animation: db2 1s infinite alternate;
}

@keyframes db2 {
  0%,
  25% {
    background-size: 4px 0, 4px 2px, 4px 2px, 4px 0, 4px 2px, 4px 2px, 4px 0,
      4px 2px, 4px 2px;
    background-position: 0 50%, 0 calc(50% - 1px), 0 calc(50% + 1px), 50% 50%,
      50% calc(50% - 1px), 50% calc(50% + 1px), 100% 50%, 100% calc(50% - 1px),
      100% calc(50% + 1px);
  }
  50% {
    background-size: 4px 100%, 4px 2px, 4px 2px, 4px 0, 4px 2px, 4px 2px, 4px 0,
      4px 2px, 4px 2px;
    background-position: 0 50%, 0 calc(0% - 1px), 0 calc(100% + 1px), 50% 50%,
      50% calc(50% - 1px), 50% calc(50% + 1px), 100% 50%, 100% calc(50% - 1px),
      100% calc(50% + 1px);
  }
  75% {
    background-size: 4px 100%, 4px 2px, 4px 2px, 4px 100%, 4px 2px, 4px 2px,
      4px 0, 4px 2px, 4px 2px;
    background-position: 0 50%, 0 calc(0% - 1px), 0 calc(100% + 1px), 50% 50%,
      50% calc(0% - 1px), 50% calc(100% + 1px), 100% 50%, 100% calc(50% - 1px),
      100% calc(50% + 1px);
  }
  95%,
  100% {
    background-size: 4px 100%, 4px 2px, 4px 2px, 4px 100%, 4px 2px, 4px 2px,
      4px 100%, 4px 2px, 4px 2px;
    background-position: 0 50%, 0 calc(0% - 1px), 0 calc(100% + 1px), 50% 50%,
      50% calc(0% - 1px), 50% calc(100% + 1px), 100% 50%, 100% calc(0% - 1px),
      100% calc(100% + 1px);
  }
}
.time-ago {
  background-color: #f1f1f1;
  color: #4c4c4c;
  padding: 0 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
}

.link-icon {
  padding: 5px;
  border-radius: 7px;
  transition: transform 0.2s, color 0.2s;
  font-size: 13px;
  font-weight: 600;
}

.link-icon:hover {
  transform: translateY(-2px);
}
.report-btn {
  transition: transform 0.2s;
}
.report-btn:hover {
  transform: translateY(-2px);
}

.audio-icon {
  transition: transform 0.2s, color 0.2s;
  cursor: pointer;
}

.audio-icon:hover {
  transform: translateY(-2px);
}

.maincontainer {
  width: 100%;
  height: 180px;
  background: none;
  cursor: pointer;
  animation: fadeIn 0.5s ease-in-out forwards;
}
.chart-message {
  animation: fadeIn 0.5s ease-in-out forwards;
}

.thecard {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transform-style: preserve-3d;
  transition: all 0.8s ease;
}

.rotated-card {
  transform: rotateX(180deg);
}

.thefront {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  backface-visibility: hidden;
  color: #fff;
  background-color: #4c4c4c;
}

.theback {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  backface-visibility: hidden;
  overflow: hidden;
  background-color: #4c4c4c;
  text-align: center;
  color: #fff;
  transform: rotateX(180deg);
}
.notification-link {
  transition: background-color 0.3s;
}
.notification-link:hover {
  background-color: #000;
}
.navbar-dropdown-link {
  padding: 5px;
  border-radius: 6px;
  margin-right: 15px;
  transition: background-color 0.2s;
}
.navbar-dropdown-link:hover {
  background-color: #aa2324;
}
.active-legend {
  background-color: #730073;
  transition: background-color 0.02s;
}
.active-legend:hover {
  color: #fff !important;
  background-color: #5c005c;
}
.legend-links {
  padding: 2px 10px;
  border-radius: 6px;
  color: #fff;
  transition: color 0.2s;
  font-size: 13px;
  border: 1px solid #7300734a;
}
.legend-links:hover {
  color: #730073;
}
progress::-webkit-progress-bar {
  background-image: url("./static/images/wave_sound.svg");
  background-size: contain;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
}
progress::-webkit-progress-value {
  background-color: rgba(255, 255, 255, 0.119);
  border-radius: 10px;
}

progress::-moz-progress-bar {
  background-color: rgba(255, 255, 255, 0.119);
  border-radius: 10px;
}
.music-player-wrapper {
  background-color: rgba(244, 51, 52, 0.7);
  border-radius: 6px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
progress {
  width: 60%;
  height: 30px;
}
.leak-bottom-part {
  margin-top: 70px;
}
.music-player-bottom {
  display: flex;
  width: 60%;
  margin: 0 auto;
  align-items: center;
  justify-content: space-around;
}
/********** Range Input Styles **********/
/*Range Reset*/
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  width: 80px;
}

/* Removes default focus */
input[type="range"]:focus {
  outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
  background-color: #fff;
  border-radius: 0.5rem;
  height: 3px;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;

  /*custom styles*/
  background-color: #fff;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-top: -3.5px;
}

/******** Firefox styles ********/
/* slider track */
input[type="range"]::-moz-range-track {
  background-color: #fff;
  border-radius: 0.5rem;
  height: 3px;
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
  /*custom styles*/
  background-color: #fff;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-top: -3.5px;
}
.dates-placeholder {
  letter-spacing: 10px;
  margin: -12px 0 20px 0;
}

/* The container */
.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
}

/* Hide the default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* The custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.4);
  transition: background-color 0.2s ease-in-out;
  padding: 3px;
  margin-top: 1px;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: rgba(0, 0, 0, 0.4);
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: rgba(0, 0, 0, 0.4);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  width: 14px;
  height: 14px;
  border-radius: 3px;
  background-color: #730073;
  transition: transform 0.2s ease-in-out;
  transform: scale(0);
  transform-origin: center;
}

/* Scale the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  transform: scale(1);
}
.checkbox-container {
  display: flex;
}
.chart-type-loader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #766df4);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 2px), #000 0);
  animation: s3 1s infinite linear;
}
@keyframes s3 {
  to {
    transform: rotate(1turn);
  }
}
.chart-message {
  top: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: inline-block;
  width: fit-content;
  color: #fff;
}
.artist-aditional-dropdown {
  visibility: hidden;
  opacity: 0;
  background-color: #761819;
  position: absolute;
  right: -157px;
  top: 49px;
  transition: visibility 0.2s, opacity 0.2s;
}
.navbar-aditional-dropdown-link {
  transition: background-color 0.2s;
  padding: 6px 6px;
  border-radius: 5px;
  margin: 0;
}
.navbar-aditional-dropdown-link:hover {
  background-color: #aa2324;
}
.artist-arrow {
  margin-left: auto;
  transform: rotate(270deg);
  font-size: 10px;
}
.notification-author {
  background-color: #fff;
  color: #7b2021;
  display: inline-block;
  padding: 2px 10px;
  border-radius: 5px;
  font-size: 12px;
}
.sort-icon {
  background-color: #d9d9d9;
}
.sort-container {
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-right: 15px;
}
.sort-icon {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.sort-button {
  transition: background-color 0.2s;
  padding-left: 7px;
  padding-right: 7px;
}
.sort-button:hover {
  background-color: #ffffff18;
}
.sort-button:nth-child(2) {
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
}
.sort-button:nth-child(1) {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.custom-select {
  position: relative;
  display: inline-block;
}

.select-selected {
  padding: 2px 10px;
  background-color: transparent;
  cursor: pointer;
  color: #730073;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid #730073;
  min-width: 150px;
}

.select-dropdown {
  display: none;
  position: absolute;
  min-width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #f9f9f9;
  top: -200px;
  min-height: 200px;
  border-radius: 5px;
}

.select-option {
  cursor: pointer;
  transition: background-color 0.2s;
}
.select-option:hover {
  background-color: #e0e0e0;
}

.custom-select.open .select-dropdown {
  display: block;
}
.leak-playback {
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
}

.leak-playback.open {
  max-height: 100px;
}
.leak-playback {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.leak-audio-first {
  background-color: #df4346;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.leak-music-player {
  width: 30%;
}
.leak-audio-scnd {
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: #dc2f32;
}
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip::after {
  content: attr(data-tooltip);
  visibility: hidden;
  width: 120px;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover::after {
  visibility: visible;
  opacity: 1;
}
.export-btn {
  background-color: #10793f;
  padding: 5px 25px;
  color: #fff;
  z-index: 9999;
  font-size: 13px;
  font-weight: 500;
  border-radius: 10px;
}
.export-btn .fa-file-csv {
  margin-left: 5px;
}
.artist-check {
  position: absolute;
  top: 0;
  right: 0;
}
.artist-checkbox {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  right: 0;
  left: 0;
  height: 70px;
  width: 70px;
  border-radius: 5px;
  background-color: #000000c5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
}
.artist-checkbox i {
  color: #32d312;
  font-size: 30px;
}
.export-options {
  display: flex;
  margin-left: 10px;
  color: #fff;
  z-index: 9999;
  align-items: center;
}
.export-options a {
  font-size: 13px;
  margin-right: 10px;
  border: 1px solid #10793f;
  padding: 3px 10px;
  border-radius: 10px;
}
.export-options a:nth-child(3) {
  border: none;
  padding: 0;
  text-decoration: underline;
}
